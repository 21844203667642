<template>
	<div>
		<b-modal
			id="modal-update-status-local-bank-account"
			ref="updateLocalBankAccountStatus"
			cancel-variant="outline-secondary"
			:ok-title="$t('Save change')"
			:cancel-title="$t('Close')"
			centered
			:title="$t('Edit')"
			@ok="confirmUpdateStatus"
            @hidden="resetModal"
		>
			<!-- form -->
			<validation-observer ref="changeLocalBankAccountStatus">
				<b-form
					method="POST"
					class="auth-reset-password-form mt-2"
					@submit.prevent="validationFormStatus"
				>

                    <!-- Bank -->
					<validation-provider
						#default="validationContext"
						name="bank"
						rules="required"
					>
						<b-form-group
							:label="$t('Bank')"
							label-for="bank"
						>
							<v-select
								v-model="data.bankId"
								:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
								:clearable="false"
								:options="bankOptions"
								:reduce="val => val.id"
								label="bank_name"
								input-id="id"
							/>
							<b-form-invalid-feedback>
								{{ validationContext.errors[0] }}
							</b-form-invalid-feedback>
						</b-form-group>
					</validation-provider>
					<!-- Field: Username -->
					<b-form-group
						:label="$t('Username')"
						label-for="username"
					>
						<b-form-input
							id="username"
							v-model="data.bankAccountName"
						/>
					</b-form-group>

                    <!-- Field: Account -->
					<b-form-group
						:label="$t('Bank account')"
						label-for="bankAccount"
					>
						<b-form-input
							id="bankAccount"
							v-model="data.bankAccount"
						/>
					</b-form-group>
					<!-- Field: Status -->
					<b-form-group
						:label="$t('Status')"
						label-for="status"
					>
						<v-select
							v-model="data.status"
							:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
							:options="statusOptions"
							:reduce="val => val.value"
							:clearable="false"
							input-id="status"
						/>
					</b-form-group>

					<!-- <validation-provider
						#default="validationContext"
						name="content"
					>
						<b-form-group
							:label="$t('Content')"
							label-for="content"
						>
							<b-form-input
								id="content"
								v-model="data.content"
								trim
							/>

							<b-form-invalid-feedback>
								{{ validationContext.errors[0] }}
							</b-form-invalid-feedback>
						</b-form-group>
					</validation-provider> -->

					<!-- Example Content -->
					<!-- <validation-provider
						#default="validationContext"
						name="example_content"
					>
						<b-form-group
							:label="$t('Example content')"
							label-for="example_content"
						>
							<b-form-input
								id="example_content"
								v-model="data.example_content"
								trim
							/>

							<b-form-invalid-feedback>
								{{ validationContext.errors[0] }}
							</b-form-invalid-feedback>
						</b-form-group>
					</validation-provider> -->
				</b-form>
			</validation-observer>
		</b-modal>
	</div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
	BLink, BRow, BCol, BForm, BFormGroup, BFormInput, BButton, BInputGroup, BInputGroupPrepend, BModal, VBModal, BInputGroupAppend, BFormTextarea, BFormInvalidFeedback,
} from 'bootstrap-vue'
import { ref, onUnmounted } from '@vue/composition-api'
import { formatRemoveTime } from '@core/utils/filter'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'

export default {
	components: {
		BLink, BRow, BCol, BForm, BFormGroup, BFormInput, BButton, BInputGroup, BInputGroupPrepend, BModal, BFormTextarea,
		BFormInvalidFeedback,
		BInputGroupAppend,
		ValidationProvider,
		ValidationObserver,
		vSelect,
	},
	directives: {
		'b-modal': VBModal,
		Ripple,
	},
	props: {
		localBankAccount: {
			type: Object,
			default: null,
		},
		statusOptions: {
			type: Array,
			required: true,
			default: null,
		},
		bankOptions: {
			type: Array,
			required: true,
			default: null,
		},
	},
	data() {
		return {
			data: {
				bankAccountName: '',
				bankId: '',
				bankAccount: '',
				status: '',
                local_bank_account_id: '',
			},
		}
	},
	watch: {
		localBankAccount: {
			deep: true,
			handler(newVal) {
				if (newVal) {
					this.getLocalBankAccountDetail(newVal)
				}
			}
		}
	},
	methods: {
		validationFormStatus() {
			this.$refs.changeLocalBankAccountStatus.validate().then(success => {
				if (success) {
					store.dispatch('payment-local-bank-account/updateLocalBankAccountStatus', this.data)
						.then(response => {
							if (response.status === 200) {
								if (response.data.code != '00') {
									this.$toast({
										component: ToastificationContent,
										props: {
											title: response.data.message,
											icon: 'AlertTriangleIcon',
											variant: 'danger',
										},
									})
								} else {
									this.$toast({
										component: ToastificationContent,
										props: {
											title: response.data.message ? response.data.message : 'success',
											icon: 'CheckIcon',
											variant: 'success',
										},
									})
									this.$emit('refetch-data')
								}
								this.$nextTick(() => {
									setTimeout(() => {
										this.$bvModal.hide('modal-update-status-local-bank-account')
									}, '500')
									setTimeout(() => {
										this.resetModal()
									}, '1000')
								})
							}
						})
						.catch(error => {
							const { config, response } = error
							this.$toast({
								component: ToastificationContent,
								props: {
									title: response.data.message,
									icon: 'AlertTriangleIcon',
									variant: 'danger',
								},
							})
						})
				}
			})
		},
		getLocalBankAccountDetail(lbaData) {
			this.data.bankAccountName = lbaData.name
            this.data.bankId = lbaData.bank_id
            this.data.bankAccount = lbaData.account
            this.data.status = lbaData.stat
            this.data.local_bank_account_id = lbaData.id
		},
		resetModal() {
			this.$emit('update:localBankAccount', null)
            this.data = {
                bankAccountName: '',
				bankId: '',
				bankAccount: '',
                status: '',
                local_bank_account_id: '',
            }
		},
		confirmUpdateStatus(bvModalEvt) {
			bvModalEvt.preventDefault()
			this.validationFormStatus()
		}
	},
	setup() {

		return {
		}
	},
}
</script>

<style>

</style>
